@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.getintouch {
  $block-name: &;

  padding-top: 60px;
  padding-bottom: 60px;
  container-type: inline-size;

  @include mq($to: md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include mq($to: sm) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__button {
    flex: 0 0 auto;

    @include mq($to: sm) {
      width: 100%;
      max-width: 100%;

      .button {
        justify-content: center;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 50px;
    border-radius: $border-radius-m;
    background: $grad-purple-horizontal;
    color: $white-100;
    gap: 45px;

    @container (max-width: #{$breakpointLg}) {
      text-align: left;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;
      padding: 40px;
    }

    @include mq($to: lg) {
      text-align: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    @include mq($to: md) {
      padding: 20px;
    }
  }

  &__inner-content {
    display: flex;
    align-items: center;
    gap: 26px;

    @include mq($to: md) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      text-align: left;
    }

    @include mq($to: sm) {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 45px;

    @container (max-width: #{$breakpointLg}) {
      text-align: left;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    @include mq($to: lg) {
      text-align: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  &__button-group {
    display: flex;
    gap: 0 20px;

    @include mq($to: md) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }

    @include mq($to: sm) {
      .button {
        justify-content: center;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__button-cell {
    @include mq($to: sm) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__text {
    color: $white-300;

    p {
      @container (max-width: #{$breakpointLg}) {
        font-weight: $font-weight-s;
      }
    }
  }

  &__title {
    flex: 0 0 auto;
    align-self: center;

    @container (max-width: #{$breakpointLg}) {
      align-self: flex-start;
    }

    @include mq($to: lg) {
      align-self: center;
    }
  }

  &__image {
    overflow: hidden;
    border-radius: 10px;
  }

  &--theme-gray {
    #{$block-name} {
      &__inner {
        border: 1px solid $white-400;
        background: $white-300;
      }

      &__title {
        color: $black-300;
      }

      &__text {
        color: $black-300;

        p {
          font-weight: $font-weight-s;
        }
      }
    }
  }

  &--theme-light {
    #{$block-name} {
      &__inner {
        background: $purple-100;
      }

      &__title {
        color: $color-main;
      }

      &__text {
        color: $black-100;

        p {
          font-weight: $font-weight-s;
        }
      }
    }
  }

  &--theme-transparent {
    #{$block-name} {
      &__inner {
        background: transparent;
        border: 1px solid $light-gray-100;
      }

      &__title {
        color: $color-main;
      }

      &__text {
        color: $black-100;

        p {
          font-weight: $font-weight-s;
        }
      }
    }
  }

  &--theme-green-gradient {
    #{$block-name} {
      &__inner {
        background: $grad-green-horizontal;
      }

      &__title {
        color: $white-100;
      }

      &__text {
        color: $white-100;
      }
    }
  }

  &--without-vertical-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &--has-buttons-group {
    #{$block-name} {
      &__inner {
        @include mq($to: 1360px) {
          text-align: center;
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }
      }

      &__title {
        @container (max-width: #{$breakpointLg}) {
          align-self: center;
        }
      }

      &__titles {
        @include mq($to: 1360px) {
          text-align: center;
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }
      }
    }
  }

  &--size-s {
    #{$block-name} {
      &__inner {
        padding: 20px;
      }
    }
  }

  &--size-m {
    #{$block-name} {
      &__inner {
        padding: 32px;
      }
    }
  }

  &--with-image {
    #{$block-name} {
      &__title {
        align-self: flex-start;
      }

      &__titles {
        flex-direction: column;
        text-align: left;
        gap: 8px;
        align-items: flex-start;

        @include mq($to: lg) {
          text-align: left;
        }
      }

      &__inner {
        @container (max-width: #{$breakpointLg}) {
          flex-direction: row;
          align-items: center;
          text-align: left;
        }

        @include mq($to: md) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }
      }
    }
  }

  &--titles-reverse {
    #{$block-name} {
      &__titles {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 8px;

        @include mq($to: sm) {
          text-align: left;
        }
      }

      &__title {
        align-self: flex-start;

        @include mq($to: lg) {
          text-align: left;
        }
      }

      &__inner {
        @container (max-width: #{$breakpointLg}) {
          flex-direction: row;
          align-items: center;
          text-align: left;
        }

        @include mq($to: md) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }
      }
    }
  }

  &--without-description {
    #{$block-name} {
      &__inner {
        @container (max-width: #{$breakpointLg}) {
          flex-direction: row;
          align-items: center;
          text-align: left;
        }

        @include mq($to: md) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }
      }

      &__title {
        @include mq($to: lg) {
          align-self: flex-start;
          text-align: left;
        }
      }
    }
  }
}
